<template>
    <div class="analogias">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Razonamiento"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1617836230/juegos-cramer/tutoriales/nivel1/percepcion/analogias_wwfife.mp4"
        text="Busque la palabra exacta"></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning" >
            <clock-down minuto="1" segundo="0" @setgameruning="setGameRuning()"></clock-down>
            <div class="an__game-box-container">
                <div class="an__game-box">
                    <div class="an__game-question">
                        {{pregunta}}:
                    </div>
                    <div class="an__game-options-container">
                        <div 
                        v-for="(item,index) in opciones"
                        :key="item.id"
                        class="an__option" 
                        @click="getKeyAnswer(item.clave,index)"
                        >
                            <div :class="['an__option-icon']">
                               <div v-if="visibleAnswer&&(index==indexSelected)" :class="['an__check-answer',answerSelected]">

                               </div>
                            </div>
                            <div class="an__option-description">
                                {{item.descripcion}}
                            </div>
                        </div>

                        <div :class="[capaProtectora]"></div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!gameRuning">
            <results-game nombrejuego="analogias" nivel="1" inteligencia="razonamiento"></results-game>
        </div>
    </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import HeaderTypeGame from '@/components/HeaderTypeGame'
import TutorialGame from '@/components/TutorialGame'
import ClockDown from '@/components/ClockDown'
import ResultsGame from '@/components/ResultsGame'
import {mapState,mapMutations} from 'vuex'
export default {
    components:{
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame,

    },
    computed:{
        ...mapState(['tutorialStatus'])
    },
    data:()=>({
        puntos:0,
        gameRuning:true,
        answerSelected:'',
        indexSelected:null,
        visibleAnswer:false,
        capaProtectora:'',
        preguntas:[
            {
                frase:'Lunes es a día como enero es a',
                alternativas:[
                    {
                        descripcion:'mes',
                        clave:true,
                    },
                    {
                        descripcion:'año',
                        clave:false,
                    },
                    {
                        descripcion:'siglo',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Italia es a país como Europa es a',
                alternativas:[
                    {
                        descripcion:'continente',
                        clave:true,
                    },
                    {
                        descripcion:'ciudad',
                        clave:false,
                    },
                    {
                        descripcion:'planeta',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Cocina es a comida como lavadora es a',
                alternativas:[
                    {
                        descripcion:'ropa',
                        clave:true,
                    },
                    {
                        descripcion:'jardín',
                        clave:false,
                    },
                    {
                        descripcion:'cristales',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Clavo es a martillo como tuerca es a',
                alternativas:[
                    {
                        descripcion:'llave inglesa',
                        clave:true,
                    },
                    {
                        descripcion:'taladro',
                        clave:false,
                    },
                    {
                        descripcion:'bisturí',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Barco es a mar como avión es a',
                alternativas:[
                    {
                        descripcion:'aire',
                        clave:true,
                    },
                    {
                        descripcion:'tierra',
                        clave:false,
                    },
                    {
                        descripcion:'barro',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Limonada es a bebida como espinacas es a',
                alternativas:[
                    {
                        descripcion:'comida',
                        clave:true,
                    },
                    {
                        descripcion:'refresco',
                        clave:false,
                    },
                    {
                        descripcion:'aperitivo',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Cocodrilo es a reptil como león es a',
                alternativas:[
                    {
                        descripcion:'mamífero',
                        clave:true,
                    },
                    {
                        descripcion:'ave',
                        clave:false,
                    },
                    {
                        descripcion:'vertebrado',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Mediterráneo es a mar como Ebro es a',
                alternativas:[
                    {
                        descripcion:'río',
                        clave:true,
                    },
                    {
                        descripcion:'lago',
                        clave:false,
                    },
                    {
                        descripcion:'ría',
                        clave:false,
                    },
                ]
            },
            {
                frase:'China es a Oriente como Europa es a',
                alternativas:[
                    {
                        descripcion:'occidente',
                        clave:true,
                    },
                    {
                        descripcion:'sur',
                        clave:false,
                    },
                    {
                        descripcion:'norte',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Peso es a kilo como distancia es a',
                alternativas:[
                    {
                        descripcion:'metro',
                        clave:true,
                    },
                    {
                        descripcion:'voltios',
                        clave:false,
                    },
                    {
                        descripcion:'gigas',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Sustracción es a resta como adición es a',
                alternativas:[
                    {
                        descripcion:'suma',
                        clave:true,
                    },
                    {
                        descripcion:'combinatoria',
                        clave:false,
                    },
                    {
                        descripcion:'ecuación',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Lenguaje es a letras como solfeo es a',
                alternativas:[
                    {
                        descripcion:'notas',
                        clave:true,
                    },
                    {
                        descripcion:'estrofas',
                        clave:false,
                    },
                    {
                        descripcion:'claves',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Cine es a película como auditorio es a',
                alternativas:[
                    {
                        descripcion:'concierto',
                        clave:true,
                    },
                    {
                        descripcion:'escultura',
                        clave:false,
                    },
                    {
                        descripcion:'concurso',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Queso es a lácteo como zanahoria es a',
                alternativas:[
                    {
                        descripcion:'vegetal',
                        clave:true,
                    },
                    {
                        descripcion:'legumbre',
                        clave:false,
                    },
                    {
                        descripcion:'carbohidrato',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Don Quijote es a Cervantes como Hamlet es a',
                alternativas:[
                    {
                        descripcion:'Shakespeare',
                        clave:true,
                    },
                    {
                        descripcion:'Hemingway',
                        clave:false,
                    },
                    {
                        descripcion:'Kundera',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Onza es a unidad de peso como palmo es a unidad de',
                alternativas:[
                    {
                        descripcion:'longitud',
                        clave:true,
                    },
                    {
                        descripcion:'temperatura',
                        clave:false,
                    },
                    {
                        descripcion:'fuerza',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Afrodita es un diosa griega igual que Venus es una diosa',
                alternativas:[
                    {
                        descripcion:'romana',
                        clave:true,
                    },
                    {
                        descripcion:'celta',
                        clave:false,
                    },
                    {
                        descripcion:'egipcia',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Marte es a planeta como Índico es a',
                alternativas:[
                    {
                        descripcion:'océano',
                        clave:true,
                    },
                    {
                        descripcion:'viento',
                        clave:false,
                    },
                    {
                        descripcion:'pico',
                        clave:false,
                    },
                ]
            },
            {
                frase:'La nobleza es a la aristocracia como el pueblo es a la',
                alternativas:[
                    {
                        descripcion:'democracia',
                        clave:true,
                    },
                    {
                        descripcion:'tecnocracia',
                        clave:false,
                    },
                    {
                        descripcion:'oligarquía',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Oscar Wilde es a escritor como Picasso es a',
                alternativas:[
                    {
                        descripcion:'pintor',
                        clave:true,
                    },
                    {
                        descripcion:'cineasta',
                        clave:false,
                    },
                    {
                        descripcion:'diseñador',
                        clave:false,
                    },
                ]
            },
            {
                frase:'La palabra "asesino" es de origen árabe como "bachiller" es de origen',
                alternativas:[
                    {
                        descripcion:'francés',
                        clave:true,
                    },
                    {
                        descripcion:'germánico',
                        clave:false,
                    },
                    {
                        descripcion:'latín',
                        clave:false,
                    },
                ]
            },
            {
                frase:'"Ergo" es a "por consiguiente", como "versus" es a',
                alternativas:[
                    {
                        descripcion:'frente a',
                        clave:true,
                    },
                    {
                        descripcion:'igual a',
                        clave:false,
                    },
                    {
                        descripcion:'seguido de',
                        clave:false,
                    },
                ]
            },
            {
                frase:'Linier es a futbol, como juez de pista es a',
                alternativas:[
                    {
                        descripcion:'tenis',
                        clave:true,
                    },
                    {
                        descripcion:'básquet',
                        clave:false,
                    },
                    {
                        descripcion:'natación',
                        clave:false,
                    },
                ]
            },

        ],
        pregunta:'',
        opciones:[]
    }),
    methods:{
         ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
        setGameRuning(){
            this.gameRuning = false
            if(this.puntos<0)
            {
                this.changeScoreGame(0)
                this.changeScoreDescription('insuficiente')
            }
            else{
                this.changeScoreGame(this.puntos)
                if(this.puntos<=200){
                this.changeScoreDescription('insuficiente')
                }
                else if(this.puntos<=400){
                    this.changeScoreDescription('escasa')
                }
                else if(this.puntos<=600){
                    this.changeScoreDescription('suficiente')
                }
                else if(this.puntos<=800){
                    this.changeScoreDescription('buena')
                }
                else{
                    this.changeScoreDescription('optima')
                }
            }
        },
        getKeyAnswer(key,index){
            this.visibleAnswer = true;
            this.indexSelected = index;
            this.capaProtectora = 'an__capa-protectora'
            if(key)
            {
                let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                a.play()
                this.answerSelected = 'an__correct-answer'
                this.puntos = this.puntos + 70;
            }
            else{
                let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                a.play()
                this.answerSelected = 'an__wrong-answer'
                this.puntos = this.puntos - 10;
            }
            window.setTimeout(()=>{
                this.gameGenerator()
            },1000)
        },
        getRandom(min, max) {
            return Math.floor(Math.random() * (max - min+1) ) + min;
        },
        reordenarArray(arr){
                let a = arr.sort(function() { return Math.random() - 0.5 })
            // console.log(a)
                return a;
            }, 
        gameGenerator(){     
            this.capaProtectora = ''
            this.visibleAnswer = false       
            let analogia = this.preguntas[this.getRandom(0,22)];
            this.pregunta = analogia.frase
            this.opciones = this.reordenarArray(analogia.alternativas);
        }
    },
    mounted(){
        this.changeMenuBarBottom(false)
        this.changeTutorialStatus(true)
        this.gameGenerator()
    }
}
</script>
<style>
.analogias{
    width: 100%;
    min-height: 100vh;
    background-color: #1F0275;
}
.an__game-box-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2em;
}
.an__game-box{
    width: 90%;
    min-height: 53vh;
    background-color: #EEEFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.an__option{
    display: flex;
    align-items: center;
    margin-top:1em;
    cursor: pointer;
}
.an__option-icon{
    width: 1.5em;
    height: 1.5em;
    box-shadow: 0px 0px 10px black;
    border-radius: 5px;
}
.an__option-description{
    font-size: 1.5em;
    margin-left: 0.5em;
    color: #0b9fb3;
}
.an__game-question{
    padding: 1em;
    font-size: 1.5em;
    color: #212121;
}
.an__game-options-container{
    padding: 1em;
    position: relative;
}
.an__check-answer{
    width: 1.5em;
    height: 1.5em;
}
.an__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
.an__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}
.an__capa-protectora{
    width: 100%;
    background-color: red;
    height: 100%;
    position: absolute;
    top: 0px;
    opacity: 0;
}
@media (min-width: 900px) and (orientation: landscape) {
    .an__game-box{
        width: 60%;
    }
    
}
</style>